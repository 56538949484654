import "./join-the-team.scss";

import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

import { JoinTheTeamForm } from "../../Forms/Info/JoinTheTeamForm";
import { TypeForm } from "../../Forms/useForm";
import withForm from "../../Forms/withForm";

const JoinTheTeam: React.FC = () => {
  const { sanityPageJoinOurTeam: data }: Queries.joinTheTeamQuery = useStaticQuery(graphql`
    query joinTheTeam {
      sanityPageJoinOurTeam {
        form {
          subtitle
          title
        }
      }
    }
  `);

  const JoinTheTeamFormUse = withForm(JoinTheTeamForm, TypeForm.JOIN_THE_TEAM);

  return (
    <section className={"o-join-the-team"}>
      <div className={"c-we-talk"}>
        <div className="m-we-talk__form">
          <div className="m-we-talk__header">
            <h2>{data?.form?.title}</h2>
            <p className={"m-join-the-team__subtitle"}>{data?.form?.subtitle}</p>
          </div>
          <JoinTheTeamFormUse />
        </div>
        <div className="m-we-talk__map">
          <StaticImage
            imgClassName={"a-we-talk__photo"}
            className={"m-we-talk__photo"}
            src={"./photo-join-us.png"}
            alt={"Trabajador haciendo picking"}
          />
        </div>
      </div>
    </section>
  );
};

export default JoinTheTeam;
