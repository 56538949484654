import "../../styles/layouts/join-the-team.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import HeroService from "../../components/Hero/HeroService/HeroService";
import Layout from "../../components/Layout/layout";
import { SEO } from "../../components/SEO/SEO";
import SubscribeNewsletter from "../../components/SubscribeNewsletter/SubscribeNewsletter";
import JoinTheTeam from "../../components/WeTalk/JoinTheTeam/JoinTheTeam";
import { Photo } from "../../types/shared";

const JoinOurTeam = ({ data }: PageProps<Queries.JoinTheTeamQuery>) => {
  return (
    <Layout>
      <div className="l-join-the-team">
        <HeroService
          title={data.sanityPageJoinOurTeam?.hero?.title}
          resume={data.sanityPageJoinOurTeam?.hero?.resume}
          photo={data.sanityPageJoinOurTeam?.hero?.photo as Photo}
        />
        <JoinTheTeam />
        {/*<WeTalk />*/}
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default JoinOurTeam;

export const Head = ({ data }: PageProps<Queries.JoinTheTeamQuery>) => (
  <SEO
    title={data.sanityPageJoinOurTeam?.seo?.title}
    robots={data.sanityPageJoinOurTeam?.seo?.seo_robots}
    description={data.sanityPageJoinOurTeam?.seo?.description}
    canonical={data.sanityPageJoinOurTeam?.seo?.canonical}
    imageAbsolutePath={data.sanityPageJoinOurTeam?.seo?.thumbnail?.asset?.url}
  />
);

export const query = graphql`
  query JoinTheTeam {
    sanityPageJoinOurTeam {
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }

      hero {
        title
        resume: _rawText
        photo {
          alt
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
        }
      }
      form {
        title
        subtitle
      }
    }
  }
`;
