import "./info.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import Submit, { SubmitType } from "../Components/Submit/Submit";
import { update_svg } from "../elements_form";
import { StateForm } from "../useForm";

export type PropsForm = PropsWithChildren<{
  handleSubmit(event: React.FormEvent<HTMLFormElement>): void;
  stateForm: StateForm;
  infoRequest: string;
}>;

export const JoinTheTeamForm: React.FC<PropsForm> = (props: PropsForm) => {
  const submitJoinUs: SubmitType = {
    stateForm: props.stateForm,
    textButtonSubmit: "Enviar",
    textButtonSubmitting: "Cargando cv ...",
    textInfo: props.infoRequest,
  };
  return (
    <form
      encType="multipart/form-data"
      name="trabaja-con-nosotros"
      method="post"
      // data-netlify="true"
      onSubmit={(event) => {
        props.handleSubmit(event);
      }}
      className="m-info-form">
      <div className="m-info-form__grid">
        <label
          hidden
          htmlFor={"name"}>
          Nombre
        </label>
        <input
          className={"a-input"}
          type="text"
          name="name"
          placeholder="Nombre"
          required
        />
        <label
          hidden
          htmlFor={"last-name"}>
          Empresa
        </label>
        <input
          className={"a-input"}
          type="text"
          name="last_name"
          placeholder="Apellidos"
          required
        />
        <label
          htmlFor={"phone"}
          hidden>
          Teléfono{" "}
        </label>
        <input
          className={"a-input"}
          type="phone"
          name="phone"
          placeholder="Teléfono"
          required
        />
        <label
          htmlFor={"email"}
          hidden>
          Email{" "}
        </label>
        <input
          className={"a-input"}
          type="email"
          name="email"
          placeholder="Email"
          required
        />
        <div className="m-input-file">
          <label
            aria-label={"Subir curriculum"}
            className="a-input-file__label"
            htmlFor={"cv"}>
            {update_svg}
          </label>
          <input
            className={"a-input a-input-file"}
            accept="application/pdf"
            size={2000000}
            type="file"
            name="cv"
            placeholder="Subir curriculum"
            required
          />
        </div>
      </div>

      <div className={"m-info-form__check-submit"}>
        <div className="m-info-form__policies">
          <div className={"m-info-form__private"}>
            <input
              type="checkbox"
              name="politica-cv"
              id="politica-cv"
              value="acepta-politica"
              required
            />
            <label
              htmlFor="politica-cv"
              className={"a-policy"}>
              He leído y acepto la{" "}
              <a
                href="/legal/politica-de-privacidad/"
                target="_blank"
                rel="nofollow noopener noreferrer">
                política de privacidad
              </a>{" "}
            </label>
          </div>
          <div className={"m-info-form__private"}>
            <input
              type="checkbox"
              name="politica-datos"
              id="politica-datos"
              value="acepta-politica"
              required
            />
            <label
              htmlFor="politica-datos"
              className={"a-policy"}>
              He leído y acepto el{" "}
              <a
                href="/legal/tratamiento-de-datos-personales/"
                target="_blank"
                rel="nofollow noopener noreferrer">
                Consentimiento para el envío de CURRÍCULUM
              </a>{" "}
            </label>
          </div>
        </div>
        <Submit submit={submitJoinUs} />
      </div>
    </form>
  );
};
